@import '../../scss/mixins/breakpoints';

.container {
    margin: 0 auto;
    padding: 0px 16px;
    width: 100%;
    max-width: calc(1110px + 32px);
    box-sizing: border-box;
    @include desktop-xl {
        max-width: calc(1440px + 32px);
    }
}