@import '../../scss/base/variables';
@import '../../scss/mixins/breakpoints';

.container {
    position: relative;
    width: 123px;
    height: 123px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
}

.upload{
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  background: $color-accent;
  border-radius: 50%;
  img{
    transform: rotate(180deg);
    width: 14px;
    height: 14px;
  }
}

