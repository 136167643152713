@import '../../../scss/mixins/breakpoints';

.section {
  margin: 0 0 20px 0;
  padding: 25px;
  background: #FFFFFF;
  border: 1px solid rgba(61, 62, 66, 0.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  &__head {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 16px;
    line-height: 1em;
    color: #3D3E42;

    @include mobile {
      font-weight: 600;
    }
  }
   &__head_padding{
       padding: 0 24px;
   }

  &__head__box {
    justify-content: space-between;
    .right_block {
      margin-right: 35px;
      font-size: 14px;
      line-height: 16px;
      font-weight: normal;
      color: #3D3E42;

      &:hover {
        > div > div:first-child {
          opacity: 0.6;
        }
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
  }
  .icon{
    margin-right: 6px;
  }
  }

.right_block{
    display: flex;
    align-items: center;
}

.right_block__title{
    margin-right: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #9fa0a2;
}
