// Settings
$desktop-xl: 2000;
$desktop-lg: 1920;
$desktop: 1441;
$desktop-sm: 1350;
$tablet: 1040;
$mobile: 668;
$mobile-landscape: 820;
$desktop-vh: 750;



@mixin desktop-xl {
	@media (min-width: ($desktop-xl * 1px)) {
		@content;
	}
}

@mixin desktop-lg {
	@media (max-width: ($desktop-lg * 1px)) {
		@content;
	}
}

@mixin desktop {
	@media (max-width: ($desktop * 1px)) {
		@content;
	}
}

@mixin desktop-sm {
	@media (max-width: ($desktop-sm * 1px)) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: ($tablet * 1px)) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: ($mobile* 1px)) {
		@content;
	}
}

@mixin mobile-landscape {
	@media (max-width: ($mobile-landscape* 1px)) and (orientation: landscape) {
		@content;
	}
}

@mixin mobile-xs {
	@media (max-width: (340 * 1px)) {
		@content;
	}
}

@mixin devices {
	@media (max-width: (($desktop - 1) * 1px)) {
		@content;
	}
}
