@import '../../scss/mixins/index.scss';
@import '../../scss/base/variables';

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50px;
    box-sizing: border-box;
    transition: .2s ease;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    font-family: $font-family-base;
    line-height: 1.14em;
    font-weight: 600;
    &:focus {
        outline: $color-accent-reverse;
        border: 1px solid  $color-accent-reverse;
    }
    strong {
        margin-bottom: 2px;
    }

}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    user-select: none;
    text-decoration: none;
    * {
        transition: 0.2s ease;
    }
}


// sizes
.size {
    &_lg {
        padding: 0px 32px;
        min-width: 200px;
        width: fit-content;
        height: 78px;
        font-size: 2.2rem;

        @include mobile {
            min-width: 100%;
            height: 52px;
            font-size: 1.4rem;
        }
    }
    &_md {
        padding: 0px 40px;
        min-width: 150px;
        width: fit-content;
        height: 52px;

        @include mobile {
            min-width: 100%;
            height: 52px;
        }
    }
    &_sm {
        padding: 0px 16px;
        min-width: 150px;
        width: fit-content;
        height: 48px;

        @include mobile {
            min-width: 100%;
            height: 52px;
        }
    }
    &_xs {
        padding: 0px 20px;
        min-width: 100px;
        width: fit-content;
        height: 40px;

        @include mobile {
            min-width: 100%;
            height: 52px;
        }
    }
    &_xxs {
        font-size: 15px;
        padding: 0 10px;
        min-width: 67px;
        width: fit-content;
        height: 30px;

        @include mobile {
            min-width: 100%;
            height: 52px;
        }
    }
}

// colors
.color {
    &_primary {
        background: #3D3E42;
        color: $color-light;
    }
    &_accent {
        background: $color-accent;
        color: $color-light;
    }
    &_accent_reverse {
        background: $color-accent-reverse;
        color: $color-light;
    }
    &_green_transparent {
      width: 100%;
      background: transparent;
       border: 1px solid #43D854;
       color: #43D854;
      &:focus{
        border: 1px solid #43D854;
      }
    }
    &_accent_transparent {
        background-color: rgba($color: $color-accent, $alpha: 0.2);
        color: $color-accent
    }
    &_sea_dashed {
        border: 1px dashed #6EE1C7;
        background-color: transparent;
        color: #3D3E42;
    }
    &_light_transparent {
        background-color: rgba($color: $color-light, $alpha: 0.2);
        color: $color-light;
    }
    &_outline {
        border: 1px solid  $color-accent-reverse;
        background: transparent;
        color: $color-accent-reverse;
    }
    &_light {
        background: #F7F7F7;
        font-weight: 400;
        color: #818587;
        strong {
            color: #3D3E42;
            font-weight: 700;
        }
    }
    &_empty {
        background: transparent;
        color: #3D3E42;
        border: 1px solid $color-accent;
        font-weight: 500;
    }
   &_sq_green {
       border-radius: 8px;
       background: #f3fcfa;
       color: #6EE1C7;
   }
   &_sq_yellow {
       border-radius: 8px;
       background: #fff8ea;
       color: #FFA800;
   }
   &_sq_red{
       border-radius: 8px;
       background: #fbf2f2;
       color: #E16E6E;
   }
}

.hover {
    &_standart {
        &:hover {
            &.color {
                &_primary {
                    background:  $color-accent;
                }
                &_accent {
                    filter: brightness(1.1);
                }
                &_accent_reverse {
                    background-color: $color-accent;
                    color: $color-light;
                }
                &_accent_transparent {
                    background-color: $color-accent;
                    color: $color-light;
                }
                &_green_transparent {
                  background: #43D854;
                  border: 1px solid #43D854;
                  color: #fff;
                }
                &_sea_dashed{
                  border: 1px solid #6EE1C7;
                  background: #6EE1C7;
                }
                &_outline {
                    border-color: $color-accent-reverse;
                    background-color: $color-accent-reverse;
                    color: $color-light;
                }
                &_light {
                    background:  $color-accent;;
                    color: rgba($color: $color-light, $alpha: 0.8);
                    strong {
                        color: $color-light;
                    }
                }
                &_light_transparent {
                    background:  $color-accent;;
                    color: rgba($color: $color-light, $alpha: 1);
                }
                &_empty{
                    background: $color-accent;
                    color: #fff;
            }
            }

        }
        &:active {
            transform: scale(0.9);
        }
    }
}

// helpers
.fill_all_width {
    width: 100%;
}

.unclickable{
    pointer-events: none;
    cursor: default;
}
