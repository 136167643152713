@import '../../../scss/base/variables';
@import '../../../scss/mixins/breakpoints';

.form {
    display: flex;
    justify-content: space-between;
    &__main {
        margin-bottom: 32px;
        width: calc(50% - 16px);
    }
    &__select {
        // display: none;
        padding: 11px 25px;
        flex-grow: 1;
    }
    &__row {
        margin: 24px 0px;
        display: flex;
        justify-content: space-between;
    }
    &__col {
        width: calc(50% - 10px);
    }
    &__second {
        width: calc(50% - 16px);
    }
}

// upload
.attach {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px dashed rgba(61, 62, 66, 0.1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 24px;
    &__title {
        margin-bottom: 4px;
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.55em;
        text-align: center;
        color: #3D3E42;

    }
    &__desc {
        margin-bottom: 50px;
        font-size: 1.4rem;
        line-height: 1.5em;
        font-weight: 500;
        text-align: center;
        color: #818587;
    }
    ul {
        margin-bottom: 64px;
        li {
            margin-bottom: 14px;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.33em;
            color: #818587;
            opacity: 0.6;
            &:last-child {
                margin-bottom: 0;
            }
            svg {
                margin-right: 8px;
            }
        }
    }
}

.submit_btn {
    margin-left: auto;
}
