// layout
@import '../../../scss/mixins/breakpoints';
@import '../../../scss/base/_colors';

.winrate {
	&__wrap {
		margin: 0 -10px;
		display: flex;
	}
	&__item {
		width: calc(50% - 20px);
		padding: 25px;
		margin: 0 10px;
		margin-bottom: 24px;
		border: 1px solid rgba(61, 62, 66, 0.1);
		border-radius: 24px;
	
		&_is_prisez {
			display: flex;
			padding-bottom: 40px;
		}

		&_is_hot {
			padding-bottom: 20px;
		}
	}
	&__box {
		width: 50%;
		&_is_diagram {
			padding-left: 20px;
			img {
				width: 100%;
				max-width: 100%;
				display: block;
			}
		}

		&_is_text {
			padding-left: 64px;
		}
	}
	&__title {
		max-width: 145px;
		padding-top: 20px;
		padding-bottom: 13px;
		font-size: 28px;
		line-height: 1.14;
		color: #3d3e42;
		font-weight: 600;
	}
	&__desc {
		font-size: 15px;
		line-height: 1.5;
		color: #3d3e42;
	}

	&__is_table {
		tbody tr td:nth-child(3) {
			color: #6ee1c7;
		}
	}
}


.hot {
	&__pic {
		width: 20px;
		height: 20px;
		margin-right: 7px;
		img {
			display: block;
		}
	}
	&__title {
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		font-size: 16px;
		line-height: 1.4;
		font-family: Gilroy;
		font-weight: bold;
	}
	&__wrap {
		padding-bottom: 5px;
		overflow-x: auto;
	}
	&__item {
		min-width: 480px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&:not(:last-child) {
			padding-bottom: 16px;
			margin-bottom: 16px;
			border-bottom: 2px solid rgba(61, 62, 66, 0.07);
		}
	}
	&__image {
		width: 42px;
		height: 42px;
		margin-right: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #f7f7f7;
		border-radius: 50%;
		img {
			display: block;
			width: 24px;
			height: 24px;
		}
	}
	&__subtitle {
		min-width: 121px;
		max-width: 121px;
		padding-right: 24px;
		font-size: 16px;
		line-height: 1.22;
		color: #3d3e42;
		font-weight: 600;
	}
	&__desc {
		min-width: 105px;
		max-width: 105px;
		padding-right: 42px;
		font-size: 14px;
		line-height: 1.4;
		color: #818587;
		font-weight: 500;
	}
	&__count {
		min-width: 74px;
		max-width: 74px;
		padding-right: 32px;
		font-size: 16px;
		line-height: 1.4;
		color: #6ee1c7;
		font-weight: 600;
	}

	&__btn {
		width: 20px;
		height: 20px;
		min-width: 20px;
		padding: 0px;
		margin-right: 12px;	
		display: block;
		border: none;
		outline: none;
		position: relative;
		cursor: pointer;
		background-color: transparent;
		&:after,
		&:before {
			position: absolute;
			content: ' ';
		}
		&:after {
			width: 8px;
			height: 8px;
			top: calc(50% - 4px);
			right: 3px;
			transform: rotate(-45deg);
			border-right: 2px solid #5a28af;
			border-bottom: 2px solid #5a28af;
			transition: 0.3s border;
		}
		&:before {
			width: 14px;
			height: 2px;
			top: 50%;
			right: 2px;
			background-color: #5a28af;
		}
	}
}