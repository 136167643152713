@import '../../scss/mixins/breakpoints';
@import '../../scss/base/variables';

.container {
    width: 100%;
}

.field {
    position: relative;
    border: 1px solid;
    border-radius: 24px;
    overflow: hidden;
}

.field_wrapper {
    display: flex;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.56em;
    input, textarea {
        padding: 1em 1.25em;
        flex-grow: 1;
        border: none;
        font-size: inherit;
        line-height: inherit;
        font-family: $font-family-base;
        outline: none;
        &::placeholder {
            font-family: $font-family-base;
        }
    }
    textarea {
        max-height: 350px;
    }
}


.title {
    margin-left: 1.25em;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.56em;
}

.error {
    margin-right: 16px;
    font-size: 1.4rem;
    line-height: 1.56em;
}

// icon
.icon {
    position: absolute;
    top: 17px;
    right: 25px;
}

// colors
.color {
    // default
    &_default {
        .title {
            color: #818587;
        }
        .field_wrapper {
            input, textarea {
                background-color: transparent;
                color: rgba(61, 62, 66, 1);
                &::placeholder {
                    color: rgba(61, 62, 66, 0.2);
                }
            }
        }

        .field {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
            border-color: rgba(61, 62, 66, 0.1);
        }
    }
    // danger (if has validation errors)
    &_danger {
        .title {
            color: rgba(238, 82, 82, 1);
        }
        .field_wrapper {
            input, textarea {
                background-color: transparent;
                color: rgb(238, 82, 82);
                &::placeholder {
                    color: rgba(238, 82, 82, 1);
                }
            }
        }

        .field {
            box-shadow: 0px 4px 10px rgba(238, 82, 82, 0.05);
            border-color: rgba(238, 82, 82, 1);
        }

        .error {
            color: rgba(238, 82, 82, 1);
        }
    }
}


// hover
.field:hover {
    border-color: $color-accent-reverse;
    .field_wrapper {
        input, textarea {
            &::placeholder {
                color: rgba(61, 62, 66, .4);
            }
        }
    }
    .icon {
        svg {
            path {
                fill: $color-accent-reverse;
                fill-opacity: .5;
            }
        }
    }
}

.container:active {
    .field {
        border-color: $color-accent-reverse;
    }
    .field_wrapper {
        input, textarea {
            &::placeholder {
                color: rgba(61, 62, 66, .4);
            }
        }
    }
    .icon {
        svg {
            path {
                fill: $color-accent-reverse;
                fill-opacity: .5;
            }
        }
    }
}
