// layout
@import '../../scss/mixins/breakpoints';
@import '../../scss/base/_colors';

.scoreboard{
    margin-top: 30px;
    @include tablet {
        overflow-x: scroll;
    }
}
.investmnets_table{
    display: none!important;
}
.default_section{
  padding: 0 25px;
}



