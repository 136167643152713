@import '../../scss/base/_colors';

.table{
  width: calc(100% - 14px);
  text-align: left;
  border-spacing: 0;
  font-weight: 500;
  margin: 0 7px;
}
.th{
  margin: 0;
  padding: 0 18px 20px 18px;
  font-size: 14px;
  line-height: 16.5px;
  font-weight: 500;
  color: $color-gray;
}
.tr{
  transition: all .3s ease;
  &:hover{
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
      .td__dashed{
          border-top: 1px solid #fff;
          border-bottom: none;
      }
  }
}

.td{
  margin: 0;
  padding: 0 18px;
  font-weight: 500;
  vertical-align: middle;
  height: 67px;
    &__dashed{
        border-bottom: 1px dashed rgba(61, 62, 66, .2);
    }
  img{
    width: 80px;
    height: 20px;
  }
}

.investmnets{
  td{
    &:nth-child(4){
      color: #6EE1C7;
    }
    &:nth-child(6){
      position: relative;
      color: #6EE1C7;
      span{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 34px;
        background: #f3fcfa;
        border-radius: 8px;
      }
    }
  }
}
