@import '../../scss/base/variables';
@import '../../scss/mixins/breakpoints';

.container {
    margin-bottom: 24px;
    padding: 16px;
    border: 1px solid rgba(61, 62, 66, 0.1);
    border-radius: 40px;
    display: flex;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    background: #FFFFFF;
    transition: .2s ease;
    @include mobile {
        flex-direction: column;
    }
}

.picture {
    position: relative;
    align-self: start;
    margin-right: 20px;
    min-width: 30%;
    max-height: 360px;
    border-radius: 40px;
    overflow: hidden;
    @include mobile {
        width: 100%;
        padding-bottom: 45%;
    }
    img {
        transition: 0.2s ease;
        border-radius: 40px;
        @include mobile {
            object-fit: cover;
        }
    }
}

.status {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 4px 8px;
    font-weight: bold;
    font-size: 1.2rem;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    background: #FA3C41;
    border-radius: 5px;
    color: #FFFFFF;
}

.main {
    margin-top: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.bookmark {
    cursor: pointer;
    @include mobile {
        margin-left: auto;
    }
    &:hover {
        svg {
            path {
                fill: $color-accent;
            }
        }
    }
}

.body {
    margin-bottom: 30px;
    max-width: 80%;
    @include mobile {
        margin-bottom: 16px;
    }
    p {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.42em;
        color: #818587;
    }
}
.header {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include mobile {
        padding-bottom: 24px;
    }
}

.title {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 1.2em;
    color: #3D3E42;
}

.featured {
    margin-left: 16px;
    align-self: center;
}

.cat {
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.42em;
    color: #818587;
    cursor: pointer;
    svg {
        margin-right: 8px;
    }
    &:hover {
        color: $color-accent;
        svg {
            path {
                fill: $color-accent;
            }
        }
    }
    @include mobile {
        position: absolute;
        top: 55px;
    }
}

.short_desc {
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.15px;
    color: rgba($color: #000, $alpha: .5);
}

.footer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.legends {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    @include mobile {
        flex-direction: column;
    }
}
.legend {
    margin-bottom: 16px;
    width: 50%;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.21em;
    display: flex;
    align-items: center;
    color: #3D3E42;
    img{
        margin-right: 10px;
    }
    @include mobile {
        width: 100%;
    }
    &:last-child {
        margin-bottom: 0;
    }
    svg {
        margin-right: 8px;
    }
    span {
        margin-left: 4px;
        display: block;
    }
}

.tags {
    margin-bottom: -15px;
    display: flex;
    flex-wrap: wrap;
    @include mobile {
        justify-content: space-between;
    }
}
.tag {
    margin-right: 30px;
    margin-bottom: 15px;
    @include mobile {
        min-width: 45%;
        margin-right: 0;
    }
    &:last-child {
        margin-right: 0;
        @include mobile {
            flex-grow: 1;
        }
    }
}

.invest_btn {
    margin-top: 16px;
}

// hovered state

.clickable:hover {
    border-color: #5A28AF;
    box-shadow: none;
    cursor: pointer;
    // .picture {
    //     img {
    //         transform: scale(1.1);
    //     }
    // }
}
