@import '../mixins/breakpoints';

html {
    font-size: 10px;
    font-family: $font-family-base;
    font-weight: $font-weight-base;

    @include tablet {
        font-size: 9px;
    }
    @include mobile {
        font-size: 10px;
    }
}

body {
    font-size: 1.6rem;
}

a {
    text-decoration: none;
}