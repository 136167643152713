.container {
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 30px;
	cursor: pointer;
	user-select: none;
}

// .placeholder {
//     font-weight: 600;
//     font-size: 18px;
//     line-height: 34px;
//     letter-spacing: 0.02em;
//     display: flex;
//     align-items: center;
//     svg {
//         margin-bottom: -1px;
//         margin-left: 24px;
//         transition: 0.2s ease;
//     }
// }

// icon
.icon {
	position: absolute;
	top: 50%;
	left: calc(100% + 18px);
	transform: translateY(-50%);
}
.icon_align_right {
	.icon {
		left: auto;
		right: 25px;
	}
}

.value {
	font-weight: normal;
	font-size: 16px;
	line-height: 34px;
	color: #3d3e42;
}

.list {
	position: absolute;
	top: calc(100% + 8px);
	left: -16px;
	padding: 24px 16px;
	min-width: 100px;
	border-radius: 8px;
	border: 1px solid rgba($color: #3d3e42, $alpha: 0.2);
	background: #fff;
	// hidden state
	opacity: 0;
	visibility: hidden;
	z-index: 2;
	&_item {
		margin-bottom: 16px;
		cursor: pointer;
		color: #3d3e42;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.is_active {
	.list {
		opacity: 1;
		visibility: 'visible';
	}
	.icon {
		svg {
			transform: rotate(180deg);
		}
	}
}
