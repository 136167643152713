@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/Gilroy/Gilroy-Regular.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/Gilroy/Gilroy-Medium.otf');
    font-weight: 500;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/Gilroy/Gilroy-Semibold.otf');
    font-weight: 600;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../assets/fonts/Gilroy/Gilroy-Bold.otf');
    font-weight: 700;
}
// @font-face {
//     font-family: 'Gilroy';
//     src: url('/Gilroy/Gilroy-ExtraBold.otf');
//     font-weight: 800;
// }
