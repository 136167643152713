@import '../../../scss/mixins/breakpoints';
@import '../../../scss/base/_colors';

.settings_form{
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    &__block{
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
    }
    &__item{
      display: flex;
      flex-direction: column;
      max-width: calc(50% - 6px);
      width: 100%;
      margin-bottom: 23px;
      font-weight: 500;
      &:nth-child(odd){
        margin-right: 12px;
      }
    }
    &__label{
    margin-left: 1.25em;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.56em;
    color: #818587;
    }
    input{
      height: 50px;
      padding: 0 60px 0 20px;
      border: 1px solid $color-light-gray;
      border-radius: 19px;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #3D3E42;
    }
    &__btn{
      width: 100%;
    }
  }

  .settings_checkboxes {
    &__item{
      margin-bottom: 25px;
    }

    input {
      display: none;
    }

    label {
      cursor: pointer;
      position: relative;
      display: block;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.9rem;
      padding: 0 35px 0 60px;
    }

    input[type="checkbox"] {
      position: absolute;
      visibility: hidden !important;
    }

    input[type="checkbox"] + label {

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -7.5px;
        box-sizing: border-box;
      }

      &:before {

        width: 35px;
        height: 22px;
        left: 0;
        background: #F7F7F7;
        border-radius: 11px;
      }

      &:after {
        width: 18px;
        height: 18px;
        top: 11px;
        left: 15px;
        background: #3D3E42;
        border: 3px solid #fff;
        border-radius: 50%;
        transition: all 200ms ease-out;
      }
    }

    input[type="checkbox"]:checked + label, input[type="radio"]:checked + label {

      &:after {
        left: 2px;
      }
    }
  }


  .settings_documents{
    margin-top: 34px;
    &__btn{
      max-width: 100%;
      min-width: 100%!important;
      width: 100%;
      margin-top: 40px;
    }
    &__submit{
      min-width: 240px!important;
      float: right;
      margin-top: 60px;
    }
    &__size{
      float: right;
    }
    &__title{
      margin-bottom: 0;
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: 600;
    }
    &__info{
      display: flex;
    }
    &__img{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin-right: 15px;
      border-radius: 50%;
      background: #F7F7F7;
    }
    &__item {
      padding: 20px 0;
      border-bottom: 1px dashed rgba(61, 62, 66, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
      svg {
        margin-right: 8px;
      }
      p {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.42em;
        color: #3D3E42;
      }
      span {
        font-size: 1.2rem;
        line-height: 1.4em;
        color: #818587;

      }
      &:hover {
        color: $color-accent;
        svg {
          path {
            fill: $color-accent;
          }
        }
      }
    }

  }
