.container {
    padding: 16px;
    border-radius: 19px;
    display: flex;
    align-items: center;
    &:hover{
        background: rgba($color: #5A28AF, $alpha: .1);
    }
}

.title {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.46em;
    color: #3D3E42;
}
.icon{
    margin-right: 19px;
}
.active{
    background: rgba($color: #5A28AF, $alpha: .1);
}
