// layout
@import '../../scss/mixins/breakpoints';
@import '../../scss/base/_colors';

.layout {
    display: grid;
    min-height: 100vh;
    grid-template-areas:
    "aside top_panel"
    "aside main";
    grid-template-columns:  calc(19.44vw - 16px)  calc(80.56vw - 16px);
    justify-content: space-between;
    grid-template-rows: 110px auto;
}

.top_panel {
    padding-right: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
    grid-area: top_panel;
    display: flex;
    align-items: center;
    background-color: #fff;
    &__right_side {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
}

.aside {
    padding: 32px 16px 0px 16px;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.07);
    grid-area: aside;
    background-color: #fff;
}

.main {
    padding-right: 32px;
    grid-area: main;
}

.logo {
    cursor: pointer;
    margin-left: 16px;
    transition: .2s ease;
    &:hover {
        opacity: 0.64;
    }
}

.navigation {
    margin-top: 80px;
    ul li {
        margin-bottom: 16px;
    }
}

.account {
    position: relative;
    margin-left: 24px;
    z-index: 1;
    &:hover{
        .account_inner{
            opacity: 1;
            visibility: visible;
        }
    }
}

.account_inner{
    position: absolute;
    right: -4px;
    top: 38px;
    width: 130px;
    padding-top: 15px;
    opacity: 0;
    visibility: hidden;
}

.account_actions {
    padding: 20px 32px;
    border-radius: 23px;
    background-color: #fff;
    box-shadow: 3px 3px 11px rgba(17, 17, 17, 0.11);
    li {
        padding: 20px 0;
        border-bottom: 1px solid #eee;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.1em;
        color: #3D3E42;
        cursor: pointer;
        &:hover {
            color: $color-accent;
        }
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
        a {
            color: inherit;
        }
    }
}
