.container {
    position: relative;
    cursor: pointer;
}

.indicator {
    position: absolute;
    right: -2px;
    top: -1px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
}