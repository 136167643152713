@import '../../scss/base/variables';
@import '../../scss/mixins/breakpoints';
@import '../../scss/mixins/container';

.page {
    background-color: aliceblue;
}

.container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @include mobile {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}

.parts {
    margin: 0 auto;
    padding: 32px 32px;
    width: 50%;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.05);
    background-color: #fff;
    @include mobile {
        padding: 32px 16px;
        width: 100%;
    }
    .part {
      width: 90%;
      @include mobile {
        width: 100%;
      }
      @include mobile-landscape {
        width: 60%;
      }
    }
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 64px;
    opacity: 0.56;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        opacity: 1;
    }
}
// todo
.section_title {
    margin-bottom: 0.68em;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.4em;
    color: #3D3E42;
    @include mobile {
        font-size: 2.4rem;
    }
}
.section_subtitle {
    margin-bottom: 0.5em;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.24em;
    color: $color-accent-reverse;
    @include mobile {
        margin-bottom: .4em;
    }
}
.section_desc {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.6em;
    color: #717171;
    @include mobile {
        margin-bottom: 24px;
        text-align: left;
        font-size: 1.4rem;
    }
    a {
        color: $color-accent-reverse;
        &:hover {
            color: rgba($color: $color-accent-reverse, $alpha: 0.8);
            text-decoration: underline;
        }
    }
}



.header {
    span {
        @extend .section_subtitle;
    }
    h1 {
        @extend .section_title;
    }
    p {
        @extend .section_desc;

    }
}

.to_homepage {
    @extend .section_desc;
    margin-bottom: 16px;
    opacity: .64;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
}

// form

.form {
    &__main {
        margin-bottom: 32px;
    }
    &__field {
        margin: 24px 0px;
    }
    &__field_row {
        display: flex;
        justify-content: space-between;
        @include mobile {
            flex-direction: column;
        }
        .form {
            &__field {
                margin-top: 0;
                margin-bottom: 0;
                min-width: calc(50% - 8px);
                @include mobile {
                    width: 100%;
                    &:first-child {
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        @include mobile {
            flex-direction: column;
        }
    }
    &__button {
        margin-right: 20px;
        width: calc(50% - 16px);
        @include mobile {
            margin-bottom: 16px;
            margin-right: 0;
            width: 100%;
        }
        &:last-child{
            margin-right: 0;
            @include mobile {
                margin-bottom: 0;
            }
        }
    }
    &__paragraph {
        margin-top: 32px;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.57em;
        color: #818587;
        @include mobile {
            margin-top: 16px;
        }
        a {
            margin: 0px 4px;
            color: inherit;
            text-decoration: underline;
            transition: 0.2s ease;
            &:hover {
                color: $color-accent;
                text-decoration: none;
            }
        }
    }
}

.error {
    @extend .form__paragraph;
    margin-top: 0;
    color: rgba(243, 79, 79, 0.89);
    @include mobile {
        margin-top: 0;
    }
}

.successed {
    @extend .form__paragraph;
    margin-top: 0;
    color: $color-accent;
    @include mobile {
        margin-top: 0;
    }
}
