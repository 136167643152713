@import '../../scss/mixins/breakpoints';
@import '../../scss/base/colors';

.user_score {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid rgba(61, 62, 66, 0.1);
  @include tablet {
    width: 200vw;
  }
  @include mobile {
    padding-right: 20px;
  }
  @include mobile-landscape  {
    width: 120vw;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
  img {
    margin-top: 0;;
  }
  &__avatar {
    width: 8%;
    img {
      min-width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &__name {
    width: 20%;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 140%;
    color: #3D3E42;
  }
  &__fractions {
    width: 15%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.15em;
    color: #3D3E42;
    svg {
      margin-right: 8px;
    }
  }
  &__graph {
    width: 10%;
  }
  &__investments {
    width: 20%;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 140%;
    color: #818587;

    span {
      margin-left: 4px;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 140%;
      color: #3D3E42;
    }
  }
  &__edit {
    cursor: pointer;
    &:hover {
      color: $color-accent;
      svg {
        path {
          fill: $color-accent;
        }
      }
    }
  }
}
